<template>
    <div class="">
    <button type="button" v-b-modal="'modal-proxy-provider'" class="btn searchBtn text-white mr-1" style="background-color: #31E85B" @click="create"><i class="fa fa-plus"></i> Create Proxy Provider</button>
        <b-modal id="modal-proxy-provider" v-model="modalShow" size="lg" title="Create Provider" centered cancel-disabled>
            <form class="form" role="form" style="" @submit.prevent="save">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Name <strong class="text-danger">*</strong></label>
                            <input type="text" v-model="item.name" placeholder="Provider" class="form-control required" required/>
                        </div>
                        <div class="form-group">
                            <label>Username <strong class="text-danger">*</strong></label>
                            <input type="text" v-model="item.username" placeholder="Username" class="form-control required" required/>
                        </div>
                        <div class="form-group">
                            <label>Password <strong class="text-danger">*</strong></label>
                            <input type="password" v-model="item.password" placeholder="Password" class="form-control required" required/>
                        </div>
                        <div class="form-group">
                            <label>IP Address<strong class="text-danger">*</strong></label>
                            <input type="text" v-model="item.ip" placeholder="0.0.0.0" class="form-control required" required/>
                        </div>
                        <div class="form-group">
                            <label>Port <strong class="text-danger">*</strong></label>
                            <input type="text" v-model="item.port" placeholder="5000" class="form-control required" required/>
                        </div>
                        <div class="form-group">
                                    <label>Status</label>
                                    <select v-model="item.status" class="form-control">
                                        <option value="" selected>-- Select one... --</option>
                                        <option value="bad">Bad</option>
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="pending">Pending</option>
                                    </select>
                                </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Url <strong class="text-danger">*</strong></label>
                            <input type="text" v-model="item.url" placeholder="provider.com" class="form-control required" required/>
                        </div>
                        <div class="form-group">
                            <label>Format Nationwide<strong class="text-danger">*</strong></label>
                            <input type="text" v-model="item.nFormat" placeholder="${username}-geo-us" class="form-control required" required/>
                        </div>
                        <div class="form-group">
                            <label>Format State Level<strong class="text-danger">*</strong></label>
                            <input type="text" v-model="item.format" placeholder="${username}-${region}-${state}" class="form-control required" required/>
                        </div>


                        <div class="form-group">
                            <label>Nationwide</label>
                            <select v-model="item.nationwide" class="form-control required" required>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label>Follow Proxy</label>
                            <select v-model="item.follow_proxy" class="form-control">
                                <option value="">--None--</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label>Side Load</label>
                            <select v-model="item.side_load" class="form-control" >
                                <option value="">--None--</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
            <template #modal-footer>
                <div class="w-100 d-flex justify-content-end">
                <b-button
                    variant="success"
                    size="sm"
                    class="mr-2"
                    @click="save"
                >
                    Save
                </b-button>
                <b-button
                    variant="danger"
                    size="sm"

                    @click="cancel"
                >
                    Close
                </b-button>
                </div>
            </template>
        </b-modal>
        <!-- <div class="row">{{item}}</div> -->
    </div>
</template>
<script scooped>
import swal from 'sweetalert'
import axios from 'axios'
export default {
    name:'createProvider',
    data(){
        return {
            item:{
                name:'',
                username:'',
                password:'',
                format:'',
                sticky:'',
                status:'',
                url:'',
                ip:'',
                port:'',
                nationwide:''
            },
            modalShow: false
        }
    },
    methods:{
        create(){
            this.item = {
                name:'',
                username:'',
                password:'',
                format:'',
                sticky:'',
                status:'',
                url:'',
                ip:'',
                port:'',
                nationwide:''
            }
            console.log('created provider');
            // swal({
            //         title: "Notice",
            //         text: "Your changes were saved successfully",
            //         icon: "success"
            //     })
            // var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}, data:this.item};
            // axios.get(`${this.$root.serverUrl}/admin/proxies/proxyErrorLogs`, {params:request}).then((resp) => {
            //     this.result = resp.data.data;
            //     console.log(this.result)
            //     // window.$('#result').modal('show')
            //     this.$root.preloader = false;
            // }).catch((err) => {
            //     console.log(err)
            //     swal({title:"Notice", text: err.response.data.error, icon:"error"});
            //     // this.$root.preloader = false;
            // })
        },
        save(){
            let item = {
                name:this.item && this.item.name?this.item.name:'',
                username:this.item && this.item.username?this.item.username:'',
                password:this.item && this.item.password?this.item.password:'',
                format:this.item && this.item.format?this.item.format:'',
                nFormat:this.item && this.item.nFormat?this.item.nFormat:'',
                sticky:this.item && this.item.sticky?this.item.sticky:'',
                status:this.item && this.item.status?this.item.status:'',                
                url:this.item && this.item.url?this.item.url:'',
                ip:this.item && this.item.ip?this.item.ip:'',
                port:this.item && this.item.port?this.item.port:'',
                side_load:this.item && this.item.side_load?this.item.side_load:'',
                follow_proxy:this.item && this.item.follow_proxy?this.item.follow_proxy:'',
                nationwide:this.item && this.item.nationwide?this.item.nationwide:'',
            }
            var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
            axios.put(`${this.$root.serverUrl}/admin/proxies/provider`, {params:request, data: item}).then((resp) => {
                let errors = resp.data.error;
                let result = resp.data.data;
                console.log(`provider resp: `, resp)
                console.log(`provider data: `, result)

                if(errors){
                    swal({title:"Notice", text: errors, icon:"error"});
                }else{
                    swal({
                            title: "Save Provider",
                            text: "Your request was completed successfully",
                            icon: "success"
                        }).then(function (value) {
                            console.log('save provider', value);
                            // this.item.status = `${value}`;
                            this.modalShow = false;
                        });
                }

            }).catch((err) => {
                console.log(err)
                swal({title:"Notice", text: err.response.data.error, icon:"error"});
                this.modalShow != false;
            })
        },
        cancel(){
            this.item.status = `${false}`;
            this.modalShow = false;
        },
    }
}
</script>
