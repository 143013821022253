<template>
    <!-- The Modal -->
    <div class="modal fade" id="proxyConfigModal">
        <div class="modal-dialog modal-lg">
            <div v-if="loading" class="loader-body">
                <div class="loader"></div>
            </div>

            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Proxy Configuration</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="row">
                        <b-tabs class="col-12" align="center" ref="proxy_tab" pills>
                            <b-tab title="Nationwide" active @click="tabbed">
                                <div class="row mt-5">
                                    <div class="col-md-8 mb-2"></div>
                                    <div class="col-md-3 mb-2">
                                        <div class="form-group">
                                            <select v-model="provider" class="form-control">
                                                <option selected value="">-- Any Provider --</option>
                                                <option
                                                    v-for="(provider, index) in providers"
                                                    :value="provider.provider"
                                                    :key="index"
                                                >
                                                    {{ provider.provider }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 mb-2">
                                        <div class="form-group">
                                            <select v-model="product" class="form-control">
                                                <option selected value="">-- Any Product --</option>
                                                <option
                                                    v-for="(product, index) in products"
                                                    :value="product.label"
                                                    :key="index"
                                                >
                                                    {{ product.value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-1 mb-2 align-self-center">
                                        <div class="form-group">
                                            <button
                                                type="button"
                                                class="btn btn-primary btn-sm"
                                                @click="create"
                                            >Add</button>
                                        </div>
                                    </div>
                                </div>
                                <ejs-grid ref="pngrid" :allowRowDragAndDrop="true" :selectionSettings="selection" :dataSource="data" :allowPaging="false" :allowSorting="false" :rowDrop="handleRowDrop">
                                    <e-columns>
                                        <e-column field="provider" headerText="Provider" text-Align="Right" width="200"></e-column>
                                        <e-column field="product" headerText="Product" text-Align="Right" width="200"></e-column>
                                        <e-column field="type" headerText="Type" text-Align="Right" width="200"></e-column>
                                        <e-column :template="statusTemplate" headerText="Status" text-Align="Center"></e-column>
                                        <e-column :template="actionTemplate" width="180"></e-column>
                                    </e-columns>
                                </ejs-grid>
                            </b-tab>
                            <b-tab title="Targeted" @click="tabbed">
                                <div class="row mt-5">
                                    <div class="col-md-8 mb-2"></div>
                                    <div class="col-md-3 mb-2">
                                        <div class="form-group">
                                            <select v-model="provider" class="form-control">
                                                <option selected value="">-- Any Provider --</option>
                                                <option
                                                    v-for="(provider, index) in providers"
                                                    :value="provider.provider"
                                                    :key="index"
                                                >
                                                    {{ provider.provider }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 mb-2">
                                        <div class="form-group">
                                            <select v-model="product" class="form-control">
                                                <option selected value="">-- Any Product --</option>
                                                <option
                                                    v-for="(product, index) in products"
                                                    :value="product.label"
                                                    :key="index"
                                                >
                                                    {{ product.value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-1 mb-2 align-self-center">
                                        <div class="form-group">
                                            <button
                                                type="button"
                                                class="btn btn-primary btn-sm"
                                                @click="create"
                                            >Add</button>
                                        </div>
                                    </div>
                                </div>
                                <ejs-grid ref="pgrid" :allowRowDragAndDrop="true" :selectionSettings="selection" :dataSource="data" :allowPaging="false" :allowSorting="false" :rowDrop="handleRowDrop">
                                    <e-columns>
                                        <e-column field="provider" headerText="Provider" text-Align="Right" width="200"></e-column>
                                        <e-column field="product" headerText="Product" text-Align="Right" width="200"></e-column>
                                        <e-column field="type" headerText="Type" text-Align="Right" width="200"></e-column>
                                        <e-column :template="statusTemplate" headerText="Status" text-Align="Center"></e-column>
                                        <e-column :template="actionTemplate" width="180"></e-column>
                                    </e-columns>
                                </ejs-grid>
                            </b-tab>
                        </b-tabs>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>

import Vue from "vue";
import {GridPlugin, Page, RowDD, Selection} from "@syncfusion/ej2-vue-grids";
import swal from "sweetalert";
import axios from "axios";

Vue.use(GridPlugin);

let Bus = new Vue();

export default {
    name: "ClusterAuditModal",
    props: ["providers"],
    provide: {
        grid: [Page, RowDD, Selection]
    },
    data() {
        return {
            temp: null,
            provider: null,
            product: null,
            loading: false,
            data: [],
            products: [
                {
                    label: "Email Verifier",
                    value: "ev"
                },
                {
                    label: "Ad Verifier",
                    value: "av"
                }
            ],
            originalGridData: [],
            pageSettings: { pageSize: 20, pageSizes: true },
            preloader: false,
            selection: { type: 'Multiple' },
            animationSettings: { effect: 'None' },
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
            statusTemplate: function () {
                return {
                    template: Vue.component('statusTemplate', {
                            template: `<div :class="data.status + ' status'"> {{ data.status }}</div>`,
                            data: function () {
                                return {
                                    data: {}
                                }
                            }
                        }
                    )
                }
            },
            actionTemplate: function () {
                return {
                    template: Vue.component('actionTemplate', {
                            template: `
                              <div>
                                  <button
                                    v-if="data.status == 'active'"
                                    type="button"
                                    class="btn btn-secondary btn-sm"
                                    @click="toggle('disable')"
                                  >Disable</button>
                                  <button
                                    v-if="data.status == 'disable'"
                                    type="button"
                                    class="btn btn-success btn-sm"
                                    @click="toggle('enable')"
                                  >Enable</button>
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    @click="remove()"
                                  ><i class="fa fa-trash"></i> </button>
                              </div>
                            `,
                            data: function () {
                                return {
                                    data: {}
                                }
                            },
                            methods: {
                                toggle(status) {
                                    swal({title: status, text:`Are you sure you want to ${status} this provider`, icon:"info", buttons:['No','Yes']}).then((val) => {
                                        if (!val) return;

                                        axios.put(`${this.$root.serverUrl}/admin/proxy-config/${this.data._id}`, {args: {status: status == 'enable' ? 'active' : 'disable'}}).then((resp) => {
                                            if (resp && resp.data && resp.data.error) {
                                                status = status == 'enable' ? "enabling" : "disabling";
                                                swal({ title: "Error", text: `There was an error ${status} provider`, icon: "error" });
                                            } else {
                                                Bus.$emit("refresh");
                                            }
                                        }).catch(() => {
                                            status = status == 'enable' ? "enabling" : "disabling";
                                            swal({ title: "Error", text:  `There was an error ${status} provider`, icon: "error" });
                                        });
                                    });
                                },
                                remove() {
                                    swal({title: status, text:`Are you sure you want to delete this provider`, icon:"info", buttons:['No','Yes']}).then((val) => {
                                        if (!val) return;

                                        axios.delete(`${this.$root.serverUrl}/admin/proxy-config/${this.data._id}`, {args: {status: status == 'enable' ? 'active' : 'disable'}}).then((resp) => {
                                            if (resp && resp.data && resp.data.error) {
                                                swal({ title: "Error", text: `There was an error deleting provider`, icon: "error" });
                                            } else {
                                                Bus.$emit("refresh");
                                            }
                                        }).catch(() => {
                                            swal({ title: "Error", text:  `There was an error deleting provider`, icon: "error" });
                                        });
                                    });
                                }
                            }
                        }
                    )
                }
            },
        }
    },
    created() {
        Bus.$on("refresh", () => {
            this.search();
        })
    },
    mounted() {
        this.search()
    },
    methods: {
        tabbed() {
            this.search()
        },
        onActionComplete(args) {
            console.log(args)
        },
        async handleRowDrop(e) {

            let gridData = this.$refs.proxy_tab?.currentTab ? this.$refs.pgrid.ej2Instances.dataSource : this.$refs.pngrid.ej2Instances.dataSource;

            let results = []
            for (let i = 0; i < gridData.result.length; i++) {
                if (i != e.fromIndex) results.push(gridData.result[i]._id)
            }

            results.splice(e.dropIndex, 0, e.data[0]._id);

            let updates = []
            for (let i = 0; i < results.length; i++) {
                updates.push({_id: results[i], priority: i})
            }

            try {
                let resp = await axios.put(`${this.$root.serverUrl}/admin/proxy-config/`, {args: {updates}})
                if (resp && resp.data && resp.data.error) {
                    throw new Error(resp.data.error)
                }
            } catch (err) {
                swal({ title: "Error", text:  `There was an error updating provider priority`, icon: "error" });
                e.cancel = true;
            }
        },
        create() {
            const grid = this.$refs.pgrid.ej2Instances;
            let count = grid.currentViewData.length;

            let idx = this.$refs.proxy_tab?.currentTab;
            let type = idx ? 'targeted' : 'nationwide';

            axios.post(`${this.$root.serverUrl}/admin/proxy-config/`, {args: {type: type, provider: this.provider, status: 'disable', priority: count}}).then((resp) => {
                if (resp && resp.data && resp.data.error) {
                    swal({ title: "Error", text: `There was an error adding provider`, icon: "error" });
                } else {
                    swal({
                        title: "Success",
                        text: "Your request was completed successfully",
                        icon: "success"
                    }).then(function () {
                        this.search();
                        this.provider = null;
                    }.bind(this));
                }
            }).catch(() => {
                swal({ title: "Error", text:  `There was an error adding provider`, icon: "error" });
            });
        },
        search: async function () {
            let request = {
                sSearch: null,
                filters: {}
            };

            this.data = null;
            this.loading = true;
            let idx = this.$refs.proxy_tab?.currentTab;
            request.filters.type = idx > 0 ? 'targeted' : 'nationwide';

            if (this.provider) request.filters.provider = this.provider;
            if (this.product) request.filters.product = this.product;

            axios.get(`${this.$root.serverUrl}/admin/proxy-config`, {params: request}).then((resp) => {
                if(resp.data && resp.data.Items) {
                    this.data = {result: resp.data.Items, count: resp.data.Items.length};
                }
            }).catch(function (err) {
                swal({ title: "Oops", text: err.message, icon: "error" });
            }).finally(() => {
                this.loading = false;
            });
        },
    }
};
</script>

<style>
.modal-dialog {
    max-width: 90%;
    height: 60vh;
    display: flex;
    max-width: 1000px;
}
.modal-header {
    background: #0d0d48;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}
.campaign-container {
    gap: 40px;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.modal-body {
    overflow: scroll;
}

.info-sec i {
    font-size: 100px;
}

#proxyConfigModal .disable {
    color: rgba(255, 0, 0, 1) !important;
}

#proxyConfigModal .status {
    width: max-content;
    padding: 5px;
    color: green;
    text-align: center;
    border-radius: 4px;
    font-weight: bold;
}

.loader-body {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9999;
    background: #000000ad;
}

.loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
