<template>
    <div>
        <div class="d-flex justify-content-between">
            <h2 style="flex:1" class="border-bottom pb-2 mb-3"><i class="fa fa-linux"></i> Proxies</h2>
            <button
                style="height: 40px"
                data-toggle='modal'
                data-target='#proxyConfigModal'
                class="btn btn-sm btn-secondary"
            ><i class="fa fa-cogs" title="settings"></i></button>
        </div>

<!--        <provied style="margin: 0 5px;"/>-->
        <div style="background-color: white; padding:2%;">
            <div class="row listing" v-if="!editor">
                <div class="col-12">
                    <div class=" ">
                        <div class="row">
                            <div class="col-md-3 mb-2" v-if="selected.length === 0">
                                <div class="form-group">
                                    <input
                                        v-model="filter.search"
                                        type="text"
                                        class="form-control"
                                        placeholder="Keyword Search"
                                    />
                                </div>
                            </div>
                            <div class="col-md-3 mb-2" v-if="selected.length === 0">
                                <div class="form-group">
                                    <select v-model="filter.status" class="form-control">
                                        <option value="" selected>-- Any Status --</option>
                                        <option value="bad">Bad</option>
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="pending">Pending</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3 mb-2">
                                <div class="form-group">
                                    <select v-model="filter.nationwide" class="form-control">
                                        <option value="" selected>-- Nationwide --</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3 mb-2">
                                <div class="form-group">
                                    <select v-model="filter.follow_proxy" class="form-control">
                                        <option value="" selected>-- Follow Proxy --</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                            </div>
<!--                            <div class="col-md-3 mb-2">-->
<!--                                <div class="form-group" v-if="selected.length > 0">-->
<!--                                    <select v-model="selectedStatus" class="form-control">-->
<!--                                        <option value="" selected>&#45;&#45; Any Status &#45;&#45;</option>-->
<!--                                        <option value="bad">Bad</option>-->
<!--                                        <option value="active">Active</option>-->
<!--                                        <option value="disabled">Disabled</option>-->
<!--                                        <option value="pending">Pending</option>-->
<!--                                    </select>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                        <div class="row">
                            <div class="col-md-3 mb-2" v-if="selected.length === 0">
                                <div class="form-group">
                                    <select v-model="filter.provider" class="form-control">
                                        <option selected value="">-- Any Provider --</option>
                                        <option
                                            v-for="(provider, index) in providers"
                                            :value="provider.provider"
                                            :key="index"
                                        >
                                            {{ provider.provider }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3 mb-2" v-if="selected.length === 0">
                                <div class="form-group">
                                    <select v-model="filter.country" @change="loadstates" class="form-control">
                                        <option value="" selected>-- Any Country --</option>
                                        <option
                                            v-for="country in countries"
                                            :key="country._id"
                                            :value="country.code"
                                        >{{ country.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3 mb-2">
                                <div class="form-group">
                                    <select v-model="filter.state" @change="loadcities" class="form-control">
                                        <option value="" selected>-- Any State --</option>
                                        <option v-for="state in states" :key="state" :value="state">{{state}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3 mb-2" >
                                <div class="form-group">
                                    <select v-model="filter.city" class="form-control">
                                        <option value="" selected>-- Any City --</option>
                                        <option v-for="city in cities" :key="city._id" :value="city.city_name">{{city.city_name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-md-2 mb-2">
                                <div class="form-group">
                                    <button
                                        type="button"
                                        v-if="selected.length === 0"
                                        class="btn btn-primary btn-block"
                                        @click="search"
                                    >
                                        <i class="fa fa-search"></i> Search Proxies
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-2 mb-2">
                                <div class="form-group">
                                    <button
                                        type="button"
                                        v-if="selected.length === 0"
                                        class="btn btn-success btn-block"
                                        @click="create"
                                    >
                                        <i class="fa fa-plus"></i> Create Proxy
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-2 mb-2">
                                <div class="form-group">
                                    <button type="button" v-if="selected.length === 0" class="btn btn-warning btn-block" @click="importCsv">
                                        Import
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-2 mb-2">
                                <div class="form-group">
                                    <button type="button" v-if="selected.length === 0" class="btn btn-success btn-block" @click="exportToExcel()">
                                        Export
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-2 mb-2" v-if="selected.length > 0">
                                <div class="form-group">
                                    <button
                                        type="button"
                                        class="btn btn-success btn-block"
                                        @click="saveBulk()"
                                    >
                                        <i class="fa fa-save"></i> Bulk Submit
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-2 mb-2" v-if="selected.length > 0">
                                <div class="form-group">
                                    <button type="button" class="btn btn-danger btn-block" @click="remove()">
                                        Delete All
                                    </button>
                                </div>
                            </div>
                            <provied style="margin: 0 5px;"/>
                        </div>
                    </div>
                    <hr/>

                    <div class="listing">
                        <table class="table table-hover bg-light text-dark">
                            <thead>
                            <tr>
                                <td></td>
                                <td>IP Address</td>
                                <td>Port</td>
                                <td>Country</td>
                                <td>State</td>
                                <td>City</td>
                                <td>Provider</td>
                                <td>Status</td>
                                <td>ID</td>
                                <td>Time Last Checked</td>
                                <td>Time Next Checked</td>

                                <td align="right"></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="data in items.data" v-bind:key="data._id">
                                <td><input style="margin: 3px auto;" class="form-check-input" type="checkbox" v-model="selected" :value="data" id="flexCheckDefault"/></td>
                                <td>{{data.ip}}</td>
                                <td>{{data.port}}</td>
                                <td>{{data.country}}</td>
                                <td>{{data.state}}</td>
                                <td>{{data.city}}</td>
                                <td>{{data.provider}}</td>
                                <td v-if="data.status!='pending' && data.status!='active'"><span class="badge badge-danger">{{data.status}}</span></td>
                                <td v-if="data.status!='bad' && data.status!='active' && data.status!='disabled'"><span class="badge badge-warning">{{data.status}}</span></td>
                                <td v-if="data.status!='bad' && data.status!='pending' && data.status!='disabled'"><span class="badge badge-success">{{data.status}}</span></td>
                                <td>{{data._id|makeID}}</td>
                                <td>{{convert(data.lastCheck)  || 'N/A'}}</td>
                                <td>{{convert(data.nextCheck) || 'N/A'}}</td>
                                <td align="right">
                                    <div class="btn-group icons">
                                        <button class="btn btn-info" @click="edit(data)" ><i class="fa fa-pencil" title="View"></i></button>
                                        <button class="btn btn-danger" @click="remove(data)"><i class="fa fa-trash" title="Delete"></i></button>
                                        <router-link class="btn btn-info" :to="{path:`/proxy/report/${data._id}`}" target="_blank"><span class="fa fa-signal" title="Report"></span></router-link>
                                        <button type="button" class="btn btn-primary mr-1" @click="test(data)"><i class="fa fa-wifi" title="Test"></i></button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div class="row">
                            <div class="col-12 col-sm-9">
                                <v-pagination
                                    v-model="page"
                                    :pageCount="Math.ceil(items.total/max)"
                                    :classes="bootstrapPaginationClasses"
                                    :labels="customLabels"
                                    @input="search"></v-pagination>
                            </div>
                            <div class="col-12 col-sm-3 text-right">
                                <span>{{items.data.length * Number(page|1)}}</span> of <span>{{items.total}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row editor" v-if="editor">
                <div class="col-12 col-sm-12">
                    <form class="form" role="form" style="" @submit.prevent="save">
                        <div class="row">

                            <div class="col-3 col-sm-3">

                                <div class="form-group">
                                    <label>IP Address</label>
                                    <input type="text" v-model="item.ip" class="form-control required" required/>
                                </div>
                            </div>
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>Port</label>
                                    <input type="number" step="1" v-model="item.port" class="form-control required" required/>
                                </div>
                            </div>
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>Protocol</label>
                                    <select v-model="item.protocol" class="form-control required" required>
                                        <option value="">--None--</option>
                                        <option value="http">HTTP</option>
                                        <option value="https">HTTPS</option>
                                        <option value="socks">socks</option>
                                    </select>
                                </div>
                            </div>


                        <div class="col-3 col-sm-3">
                            <div class="form-group">
                                <label>Provider</label>
                                <select v-model="item.provider" class="form-control required" required>
                                    <option value="">--None--</option>
                                    <option
                                        v-for="(provider, index) in providers"
                                        :value="provider.provider"
                                        :key="index"
                                    >
                                        {{ provider.provider }}</option>
                                </select>
                            </div>
                        </div>
                         </div>

                            <div class="row">
                                <div class="col-3 col-sm-3">

                                    <div class="form-group">
                                        <label>Username</label>
                                        <input type="text" v-model="item.username" class="form-control"/>
                                    </div>
                                </div>
                                <div class="col-3 col-sm-3">
                                    <div class="form-group">
                                        <label>Nationwide</label>
                                        <select v-model="item.nationwide" class="form-control required" required>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>Follow Proxy</label>
                                    <select v-model="item.follow_proxy" class="form-control required" required>
                                        <option value="">--None--</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                                </div>


                                <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>Side Load</label>
                                    <select v-model="item.side_load" class="form-control required" required>
                                        <option value="">--None--</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                                </div>
<!--                                <div class="col-3 col-sm-3">-->
<!--                                    <div class="form-group">-->
<!--                                        <label>Country</label>-->
<!--                                        <select v-model="item.country" class="form-control text-capitalize">-->
<!--                                            <option value="">&#45;&#45; None &#45;&#45;</option>-->
<!--                                            <option v-for="country in $root.countries" :key="country._id" :value="country.code">{{country.name}}</option>-->
<!--                                        </select>-->
<!--                                    </div>-->
<!--                                    </div>-->
                            </div>

                        <div class="row">
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>Country</label>
                                    <select v-model="item.country" class="form-control text-capitalize">
                                        <option value="">-- None --</option>
                                        <option v-for="country in $root.countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>State</label>
                                    <select v-model="item.state" class="form-control text-capitalize">
                                        <option value="">-- None --</option>
                                        <option v-for="state in $root.states" :key="state._id" :value="state.code">{{state.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>City</label>
                                    <input type="text" v-model="item.city" class="form-control"/>
                                </div>
                            </div>
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>Status</label>
                                    <select v-model="item.status" class="form-control">
                                        <option selected="" disabled="">-- Select one... --</option>
                                        <option value="bad">Bad</option>
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="pending">Pending</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="text" v-model="item.password" class="form-control"/>
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <div class="form-group d-flex justify-content-center">
                                    <button type="button" class="btn btn-secondary  btn-md mr-2" @click="cancel"><span class="glyphicon glyphicon-remove"></span> Cancel</button>
                                    <button type="submit" class="btn btn-success btn-md"><span class="glyphicon glyphicon-ok"></span> Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        <div class="modal" id="upload" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Import Proxies</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form class="form" role="form">
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label>File</label>
                                        <input id="fileUpload" ref="file" type="file" accept=".csv" @change="onSelectFile">
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label>Provider</label>
                                        <select v-model="upload.provider" class="form-control">
                                            <option value="">-- None --</option>
                                            <option
                                                v-for="(provider, index) in providers"
                                                :value="provider.provider"
                                                :key="index"
                                            >
                                                {{ provider.provider }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label>Protocol</label>
                                        <select v-model="upload.protocol" class="form-control">
                                            <option value="">-- Protocol --</option>
                                            <option value="http">HTTP</option>
                                            <option value="form">HTTPS</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label>IP</label>
                                        <input type="text" v-model="upload.ip" class="form-control"/>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label>Port</label>
                                        <input type="text" v-model="upload.port" class="form-control"/>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label>Username</label>
                                        <input type="text" v-model="upload.username" class="form-control"/>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input type="text" v-model="upload.password" class="form-control"/>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label>Action</label>
                                        <select v-model="upload.action" class="form-control">
                                            <option value="">-- Action --</option>
                                            <option value="override">Override</option>
                                            <option value="replace">Replace</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="importProxies">Import</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="result" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Proxy Check Result</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>ip: {{ result.ip }}</p>
                        <p>country: {{ result.country_name }}</p>
                        <p>state: {{ result.subdivision_1_name }}</p>
                        <p>city: {{ result.city_name }}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <Config :providers="providers" />
    </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'
import vPagination from 'vue-plain-pagination'
import moment from 'moment-timezone';
import provied from "@/components/dashboard/proxy/provider";
import Config from "@/components/proxy/config";

export default {
    name: 'Proxies',
    props:['user'],
    components: {
        vPagination,
        provied,
        Config
    },
    data: function () {
        return {
            selected:[],
            mulSelect: false,
            selectedStatus: '',
            item: {},
            upload: {},
            result: {},
            items: {total:0, data:[]},
            countries:[],
            states:[],
            cities:[],
            providers:[],
            editor: false,
            filter: {name:'', status:'',provider:'',country:'',state:'',city:'',follow_proxy:'',nationwide:''},
            max: 25,
            page: 1,
            bootstrapPaginationClasses: {
                ul: 'pagination',
                li: 'page-item',
                liActive: 'active',
                liDisable: 'disabled',
                button: 'page-link'
            },
            customLabels: {
                first: 'First',
                prev: 'Previous',
                next: 'Next',
                last: 'Last'
            },
            searching: false
        }
    },


    watch: {
        selected: function(value) {
            console.log(value);
            this.mulSelect = true;
        }
    },

    created(){
        this.filter.country = (this.$route.query.country || "");
        this.filter.state = (this.$route.query.state || "");
        this.filter.provider = (this.$route.query.provider || "");
        this.filter.status = (this.$route.query.status || "");
        // this.loadcountries();
        // this.loadstates();
        // this.loadcities();
        // this.getProviders();
        this.search();
        this.loadcountries();
        this.getProviders();
        window.addEventListener("keydown",this.windowListener);
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    methods:{
        convert(time) {
            return moment.tz(time, this.user.timezone).format('MMMM Do YYYY, h:mm:ss a')
        },

        getProviders() {
            //Load the providers
            // this.$root.preloader = true;
            this.providers = [];

            axios.get(`${this.$root.serverUrl}/admin/proxies/providers`).then(function(resp){
                this.$root.preloader = false;
                this.providers = (resp && resp.data && resp.data.data) ? resp.data.data: [];
            }.bind(this));
        },
        windowListener: function(e){
            if(e.keyCode === 13 && !this.editor && !this.searching){
                this.search();
            }
        },
        importCsv() {
            window.$('#upload').modal('show')
        },
        onSelectFile: function(e) {
            this.upload.file = e.srcElement.files[0];
        },
        importProxies() {
            let errmsg;
            let formData = new FormData();

            if(!this.upload.provider) errmsg = "Please select a proxy provider";
            if(!this.upload.file) errmsg = "Please select a file to be uploaded";
            //if(!this.upload.ip) errmsg = "Please enter the proxy ip";
            // if(!this.upload.port) errmsg = "Please enter the proxy port";
            if(!this.upload.protocol) errmsg = "Please select a protocol";
            //if(!this.upload.action) errmsg = "Please select an action";

            if(errmsg) {
                swal({title:"Error", text: errmsg, icon:"error"});
                return
            }

            // this.$root.preloader = true;
            formData.append('provider', this.upload.provider);
            formData.append('username', this.upload.username);
            formData.append('password', this.upload.password);
            formData.append('protocol', this.upload.protocol);
            formData.append('ip', this.upload.ip);
            formData.append('port', this.upload.port);
            formData.append('action', this.upload.action);
            formData.append('file', this.upload.file);

            axios.post(`${this.$root.serverUrl}/admin/proxies/import`, formData, {headers: { apikey: this.$root.apikey, contentType: 'multipart/form-data'}}).then(() => {
                swal({title:"Notice", text:"Your changes were saved successfully", icon:"success"}).then(() => {
                    window.$('#upload').modal('hide')
                    this.search();
                });
            }).catch((err) => {
                console.log(err)
                swal({title:"Error", text: 'Communication Error', icon:"error"});
                this.$root.preloader = false;
            });
        },
        test(proxy) {
            // this.$root.preloader = true;
            var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
            return axios.get(`${this.$root.serverUrl}/admin/proxies/test/${proxy._id}`, {params:request}).then((resp) => {
                this.result = resp.data.data;
                console.log(this.result)
                window.$('#result').modal('show')
                this.$root.preloader = false;
            }).catch((err) => {
                console.log(err)
                swal({title:"Notice", text: err.response.data.error, icon:"error"});
                this.$root.preloader = false;
            })
        },
        create: function(){
            this.item = {};
            this.editor = true;
        },

        saveBulk() {
            //Edits all proxies that are selected
            axios.post(`${this.$root.serverUrl}/admin/proxies/edit/bulk`, {list: this.selected, status:this.selectedStatus } ).then(() => {
                swal({
                    title: "Notice",
                    text: "Your changes were saved successfully",
                    icon: "success"
                })
                this.selected = []
                this.search();

            }).catch(() => {
                swal({title: "Notice", text: "Update unsuccessful", icon: "error"});
            })
        },

        save: function(){

            //Handle saving the changes
            var request = {'apikey': this.$root.apikey, id: this.item._id, args: this.item};
            // this.$root.preloader = true;

            axios({
                url: `${this.$root.serverUrl}/admin/proxies`,
                data: request,
                responseType: 'json',
                method: (this.item['_id']) ? 'POST' : 'PUT'
            }).then(function (resp) {
                //Process the results
                this.$root.preloader = false;
                if (resp.data && !resp.data.error) {
                    //Saved successfully, now update the parent
                    swal({
                        title: "Notice",
                        text: "Your changes were saved successfully",
                        icon: "success"
                    }).then(function (val) {
                        if (val) {
                            this.editor = false;
                            this.search();
                        }
                    }.bind(this));
                } else {
                    //Handle errors
                    this.errormsg = (resp.data && resp.data.error) ? resp.data.error : false;
                    this.$root.preloader = false;
                }
            }.bind(this)).catch(function (err) {
                this.$root.preloader = false;
                this.errormsg = err.message || "An unexpected error occured";
                swal({title: "Error", text: this.errormsg, icon: "error"});
            }.bind(this))
        },
        remove: function(obj){
            //Handle removing the item

            //if there are selected items, items will be deleted in bulk
            if(this.selected.length > 0) {

                swal({
                    title: "Remove",
                    text: "Are you sure",
                    icon: "info",
                    buttons: ['No', 'Yes']
                }).then(val => {

                    if (!val) return;

                    axios.delete(`${this.$root.serverUrl}/admin/proxies/delete/bulk`, {data: {list: this.selected}}).then(() => {
                        swal({
                            title: "Remove",
                            text: "Your request was completed successfully",
                            icon: "success"
                        }).then(function () {
                            this.selected = []
                            this.search();
                        }.bind(this));
                    })
                })



            } else {
                swal({
                    title: "Remove",
                    text: "Are you sure",
                    icon: "info",
                    buttons: ['No', 'Yes']
                }).then(function (val) {
                    if (!val) return;

                    var request = {'apikey': this.$root.apikey, id: obj._id};
                    // this.$root.preloader = true;

                    axios({
                        url: `${this.$root.serverUrl}/admin/proxies/${obj._id}`,
                        data: request,
                        responseType: 'json',
                        method: 'DELETE'
                    }).then(function (resp) {
                        //Process the results
                        this.$root.preloader = false;
                        if (resp.data && !resp.data.error) {
                            //Saved successfully, now update the parent
                            swal({
                                title: "Remove",
                                text: "Your request was completed successfully",
                                icon: "success"
                            }).then(function () {
                                this.search();
                            }.bind(this));
                        } else {
                            //Handle errors
                            swal({title: "Remove", text: "Unable to complete your request", icon: "error"});
                        }
                    }.bind(this))
                        .catch(function (err) {
                            this.$root.preloader = false;
                            this.errormsg = err.message || "An unexpected error occured";
                            swal({title: "Error", text: this.errormsg, icon: "error"});
                        }.bind(this))
                }.bind(this));
            }
        },
        edit: function(obj){
            //Edit the vendor item
            this.editor = true;
           // if(obj.nationwide == undefined) obj.nationwide = 'no'
            this.item = Object.clone({}, obj);
        },
        cancel: function(){
            this.editor = false;
            this.errormsg = false;
        },
        loadcountries: function(){
            var request = {'apikey': this.$root.apikey,sSearch:''};

            return axios.get(`${this.$root.serverUrl}/admin/countries`, {params:request}).then(function(resp){
                //Store the stats
                this.countries = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        loadstates: function(){
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.filter.country) request.filters.country_iso_code = this.filter.country;

            return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then(function(resp){
                //Store the stats
                this.states = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        loadcities: function(){
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.filter.country) request.filters.country_iso_code = this.filter.country;
            if(this.filter.state) request.filters.subdivision_1_iso_code = this.filter.state;

            return axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params:request}).then(function(resp){
                //Store the stats
                this.cities = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        search: function(){
            this.searching = true;
            //Load the items
            this.page = (this.page < 1) ? 1 : this.page;
            var page
            if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                //Pagination triggered event
                console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                page = (this.page)? ((this.page === 1)? 0 : Number(this.page-1) ) : 0;
            } else
                page = 0;

            var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
            if(this.filter.search) request.sSearch = this.filter.search;
            if(this.filter.status) request.filters.status = this.filter.status;
            if(this.filter.provider) request.filters.provider = this.filter.provider;
            if(this.filter.country) request.filters.country = this.filter.country;
            if(this.filter.state) request.filters.state = this.filter.state;
            if(this.filter.city) request.filters.city = this.filter.city;
            if(this.filter.from) request.from = this.filter.from.replace(/-/g,'/');
            if(this.filter.to) request.to = this.filter.to.replace(/-/g,'/');
            if(this.filter.follow_proxy) request.filters.follow_proxy = this.filter.follow_proxy;
            if(this.filter.nationwide) request.filters.nationwide = this.filter.nationwide;
            request.page = page
            request.max = this.max;
            // this.$root.preloader = true;
            axios.get(`${this.$root.serverUrl}/admin/proxies`, {params:request}).then(function(resp){
                //Store the stats
                this.items = (resp.data)? resp.data : {total:0, data:[]};
                this.$root.preloader = false;
                this.searching = false;
            }.bind(this));
        },
        download: function(){
            // this.$root.preloader = true;
            return axios.get(`${this.$root.serverUrl}/download`).then(function () {
                //Download the proxies from the api vendor
                this.$root.preloader = false;
                this.search();
            }.bind(this))
        },
        exportToExcel: function(){
            var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
            if(this.filter.search) request.sSearch = this.filter.search;
            if(this.filter.status) request.filters.status = this.filter.status;
            if(this.filter.provider) request.filters.provider = this.filter.provider;
            if(this.filter.country) request.filters.country = this.filter.country;
            if(this.filter.state) request.filters.state = this.filter.state;
            if(this.filter.city) request.filters.city = this.filter.city;
            // this.$root.preloader = true
            axios({
                url: `${this.$root.serverUrl}/admin/proxies/export/proxy`,
                params: request,
                method: 'GET',
                responseType: 'blob',
            }).then((resp) => {
                var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', `proxies${new Date().toTimeString()}.csv`);
                document.body.appendChild(fileLink);

                fileLink.click();
                this.$root.preloader = false
            }).catch((err)=>{
                this.$root.preloader = false
                console.log('report.error', err)
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
            });
        }
    }
}
</script>

<style scoped>
.searchBtn span {
    max-width: 0;
    -webkit-transition: max-width 1s;
    -moz-transition: max-width 1s;
    -o-transition: max-width 1s;
    transition: max-width 1s;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
}
button:hover span {
    max-width: 9rem;
}
</style>



